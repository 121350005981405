export default {
  data () {
    return {
      queryText: ''
    }
  },
  methods: {
    filterOnLabelAndValue (item, queryText, itemText) {
      const text = itemText.toLowerCase()
      const value = item.value.toLowerCase()
      const searchText = queryText.toLowerCase()
      return text.indexOf(searchText) > -1 || value.indexOf(searchText) > -1
    }
  }
}
