<template>
  <v-autocomplete
    :value="value"
    :items="items"
    @change="changed"
    color="primary darken-1"
    item-color="primary darken-1"
    dense
    chips
    small-chips
    :label="label"
    multiple
    clearable
    hide-details
    :loading="dataPending"
    :open-on-clear="false"
    :filter="filterOnLabelAndValue"
    :search-input.sync="queryText"
    outlined
  >
    <!-- 'Select all' item -->
    <template #prepend-item v-if="items.length > 1 && !queryText">
      <v-list-item ripple @click="toggled">
        <v-list-item-action>
          <v-icon :color="value.length > 0 ? 'primary darken-1' : ''">{{ icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>

    <!-- Custom selection -->
    <template #selection="{ parent, item, index }">
      <!-- Custom chip -->
      <v-chip v-if="index < 2" :color="parent.itemColor" :small="parent.smallChips">
        <span class="ellipsis-chip-label">{{ item.text }}</span>
        <v-icon v-if="parent.deletableChips" small right @click="parent.selectItem(item)">cancel</v-icon>
      </v-chip>

      <!-- Appending text -->
      <span
        v-if="index === 3"
        class="grey--text text-caption mr-1"
      >(+{{ value.length - 2 }} others)</span>
    </template>
  </v-autocomplete>
</template>

<script>
import acFilterMixin from '@/utils/mixins/filters/autocompleteFilter.mixin.js'
import { layerControlMixin } from '../utils/mixins/layerControl.mixin.js'

export default {
  name: 'pibot-mapbox-layer-select',
  mixins: [acFilterMixin, layerControlMixin],
  props: {
    value: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: 'Miscellaneous Layers'
    }
  },
  computed: {
    selectedAll () {
      return this.value.length === this.items.length
    },
    selectedSome () {
      return this.value.length > 0 && !this.selectedAll
    },
    selectedNone () {
      return this.value.length === 0
    },
    icon () {
      if (this.selectedAll) return 'check_box'
      if (this.selectedSome) return 'indeterminate_check_box'
      return 'check_box_outline_blank'
    }
  },
  methods: {
    changed (newValue) {
      this.$emit('change', newValue)
    },
    toggled () {
      this.$emit('toggle')
    }
  }
}
</script>
